import { USER_TYPE, AFF_ID, USER_MAPPING } from '../common/permissionConstants';

export const checkAffiliate = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'affiliate';
};

export const checkOPS = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'OPSUser';
};

export const checkAdmin = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'admin' || checkOPS();
};

export const checkAgent = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'agent';
};

export const checkNativeUser = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'nativeUser';
};

export const checkSuperAffiliate = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return (
    USER_MAPPING[localStorageValue] === 'superAffiliate' || checkNativeUser()
  );
};

export const checkAccounting = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'accounting';
};

export const checkTech = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'tech';
};

export const checkAffiliateManager = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'affiliateManager';
};

export const checkPowerUser = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'powerUser';
};

export const checkAmb = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'amb';
};

export const checkIsRemarketing = () => {
  const localStorageValue = localStorage.getItem(USER_TYPE);
  return USER_MAPPING[localStorageValue] === 'isRemarketing';
};

export const checkAffiliateDropdown = () =>
  (checkAffiliate() ||
    checkSuperAffiliate() ||
    checkAmb() ||
    checkIsRemarketing()) &&
  localStorage.getItem(AFF_ID) !== null;
