import { type IRoute } from './types';

export const changeListSections = <T extends Record<string, IRoute>>(
  list: T,
  newSection: Array<string>
) => {
  return Object.entries(list).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        ...value,
        collapsableSectionTitle: newSection
      }
    };
  }, {} as T);
};
